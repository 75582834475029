<template>
  <div :class="mode == 'devel' ? 'block-show' : 'hide'" class="app-block"></div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useCookies } from "vue3-cookies";

export default {
  name: "VueAuth",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      isLogin: false,
      envMode: "devel",
      uuid: "",
      token: "",
    };
  },
  async created() {
    this.mode = this.$store.state.envMode;
    this.initUuid();
    this.initAxiosHeader();
    this.initToken();
    this.$watch(
      () => this.$store.state.axiosHeaders,
      () => {
        this.axiosHeaders = this.$store.state.axiosHeaders;
      }
    );
  },
  components: {},
  methods: {
    initUuid() {
      let uuidItem =
        document.cookie
          .match("(^|;)\\s*" + this.appName + "-uuid\\s*=\\s*([^;]+)")
          ?.pop() || "";
      if (uuidItem === "") {
        uuidItem = uuidv4();
        this.cookies.set(this.appName + "-uuid", encodeURI(uuidItem));
      }
      this.uuid = uuidItem;
    },
    initAxiosHeader() {
      this.$store.commit("setAxiosHeaders", {
        key: "uuid",
        val: this.uuid,
      });
    },
    initToken() {
      let tokenItem =
        document.cookie
          .match("(^|;)\\s*" + this.appName + "-token\\s*=\\s*([^;]+)")
          ?.pop() || "";
      if (tokenItem !== "") {
        this.token = decodeURI(tokenItem);
        this.checkToken();
      }
    },
    checkToken() {
      axios
        .post(
          this.apiHost + "/api/check_token",
          {},
          {
            headers: {
              Authorization: "Bearer " + this.token,
              uuid: this.uuid,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => this.checkTokenResponse(response))
        .catch(function (error) {
          console.error("checkToken", error);
        });
    },
    async checkTokenResponse(response) {
      if (response.data.result === true) {
        await this.$store.commit("setIsLogin", true);
        await this.$store.commit("setAxiosHeaders", {
          key: "Authorization",
          val: "Bearer " + this.token,
        });
        await this.$store.commit("setAxiosHeaders", {
          key: "uuid",
          val: this.uuid,
        });
        await this.$store.commit("setAxiosHeaders", {
          key: "Accept",
          val: "application/json",
        });
        await this.$store.commit("setAxiosHeaders", {
          key: "Content-Type",
          val: "application/json",
        });
        this.getUserInfo();
      } else {
        this.cookies.remove(this.appName + "-token", null);
      }
    },
    getUserInfo() {
      axios
        .get(this.apiHost + "/api/user", {
          headers: this.$store.state.axiosHeaders,
        })
        .then((response) => this.setUserInfo(response))
        .catch(function (error) {
          console.error("checkToken", error);
        });
    },
    async setUserInfo(response) {
      if (response.data.result) {
        await this.$store.commit("setUserInfo", response.data.content[0]);
      }
      this.$store.commit("setIsLogin", true);
    },
  },
};
</script>
