<template>
  <vue-env></vue-env>
  <vue-auth></vue-auth>
  <vue-loading></vue-loading>
  <div class="main-content" ref="mainContent">
    <router-view />
  </div>
  <FooterContent></FooterContent>
</template>

<script>
import VueEnv from "@/components/VueEnv.vue";
import VueLoading from "@/components/VueLoading.vue";
import VueAuth from "@/components/VueAuth.vue";
import { defineAsyncComponent } from "vue";

export default {
  name: "App",
  components: {
    VueEnv,
    VueLoading,
    VueAuth,
    FooterContent: defineAsyncComponent(() =>
      import("@/components/FooterContent.vue")
    ),
  },
  data() {
    return {
      deviceType: "mobile",
    };
  },
  created() { },
  mounted() {


  }
};
</script>

<style>
body {
  background: #333 !important;
}

.main-content {
  margin: auto;
  padding: 15px;
  background: #fff;
  color: #000;
  max-width: 412px;
  height: calc(100vh - 30px);
  border: solid 1px #000;
}


.page-item:first-child .page-link {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 992px) {}
</style>
