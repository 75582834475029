<template>
  <div class="text-center">
    <div class="row">
      <div class="col-10 mx-auto">
        <div class="logo">
          <img src="@/assets/app_logo.png" class="me-3" />
        </div>
        <HomeContent v-if="isLogin"></HomeContent>
        <LoginContent v-if="!isLogin"></LoginContent>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "HomeView",
  components: {
    HomeContent: defineAsyncComponent(() =>
      import("@/components/HomeContent.vue")
    ),
    LoginContent: defineAsyncComponent(() =>
      import("@/components/LoginContent.vue")
    ),
  },
  data() {
    return {
      isLogin: false,
    };
  },
  created() {
    this.isLogin = this.$store.state.isLogin;
  },
};
</script>
<style>
.logo img {
  width: auto;
  height: auto;
  object-fit: cover;
  object-position: top;
}
</style>
