<template>
  <div class="loader" v-bind:class="{ hide: isHide }" :style="cssStyle">
    <div class="content d-flex justify-content-center">
      <img src="@/assets/logo.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "VueLoading",
  data() {
    return {
      isHide: false,
    };
  },
  props: {
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  computed: {
    cssStyle() {
      return {
        "background-color": this.bgColor,
        color: this.color,
      };
    },
  },
  methods: {
    onWindowLoad() {
      setTimeout(() => (this.isHide = true), 1000);
    },
    reload() {
      location.reload();
    },
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", this.onWindowLoad);
  },
};
</script>

<style>
.loader {
  position: fixed;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
  color: #000;
  max-width: 100%;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.loader div.content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgb(255, 255, 255);
}

.loader div.content img {
  width: auto;
  height: auto;
  animation: normal forwards;
  -webkit-animation: infinite forwards fadeInOut 3s;
  -moz-animation: infinite forwards fadeInOut 3s;
  -o-animation: infinite forwards fadeInOut 3s;
  -ms-animation: infinite forwards fadeInOut 3s;
}

.hide {
  display: none;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  84% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
