import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },        
    {
        path: '/task',
        name:'TaskView',
        component: () => import(/* webpackChunkName: "TaskView" */ '@/views/TaskView.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
